import React from "react";
import PurchaseRow from "./PurchaseRow";


const PurchasesTable = ({
  user,
  purchases,
  hideColumns,
  editExpiration,
  confirmCancel,
  confirmRevoke,
}) => {

  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      purchases.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
      
        return dateB - dateA;
      });
      
      return purchases.map((purchase) => (
        <PurchaseRow
          user={user}
          purchase={purchase}
          hideColumns={hideColumns}
          key={purchase.purchase_id}
          editExpiration={editExpiration}
          confirmCancel={confirmCancel}
          confirmRevoke={confirmRevoke}
        />
      ));
    }
  };
  return (
    <div className="table-responsive" style={{minHeight: '200px'}}>
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Tipo</td>
            <td>Cliente</td>
            <td>Etiquetas</td>
            <td>Producto</td>
            <td>Fecha Límite</td>
            <td>Fecha Pago</td>
            <td>Monto</td>
            <td>Corte / Sig.</td>
            <td>Método Pago</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
