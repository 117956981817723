import React from "react";

const HeaderRow = ({ headers }) => {
  const renderColumns = () => {
    if (Array.isArray(headers)) {
      return headers.map((header, index) => {
        return(
          <div key={index} className={`col ${header === 'Acciones' ? 'text-center' : ''}`}>
            {header}
          </div>
        )
      });
    }
  };

  return (
    <div className="row border bg-light py-1 mx-0 bold">
      {renderColumns()}
    </div>
  );
};

export default HeaderRow;
