import React, { createContext, useReducer, useContext } from "react";
import ExpensesService from "../services/ExpensesService";
import ExpensesReducer from "../reducers/ExpensesReducer";
import {
  EXPENSES_RECEIVED,
  SET_EXPENSE,
  CREATE_EXPENSE,
  SET_PROPERTY_EXPENSE,
} from "../types/expenses";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  expenses: null,
  expense: null,
};

export const ExpensesContext = createContext(initialState);

export const ExpensesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ExpensesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getExpenses = (filters) => {
    ExpensesService.getExpenses(filters)
      .then((response) => {
        const { expenses } = response.data;
        dispatch({ type: EXPENSES_RECEIVED, payload: expenses });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const downloadExpenses = (filters) => {
    ExpensesService.downloadExpenses(filters)
      .then((response) => {
        console.log(response.data);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Expenses_${filters.start_date}_${filters.end_date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const getSingleExpense = (expense_id) => {
    ExpensesService.getSingleExpense(expense_id)
      .then((response) => {
        const { expense } = response.data;
        dispatch({ type: SET_EXPENSE, payload: expense });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setExpense = (expense) => {
    dispatch({ type: SET_EXPENSE, payload: expense });
  };

  const createExpense = () => {
    dispatch({ type: CREATE_EXPENSE });
  };

  const setPropertyExpense = (key, value) => {
    dispatch({ type: SET_PROPERTY_EXPENSE, payload: { key, value } });
  };

  const saveExpense = (expense, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ExpensesService.putExpense;
    if (isNaN(parseInt(expense.expense_id))) {
      service = ExpensesService.postExpense;
    }
    service(expense)
      .then(() => {
        success("Gasto guardado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteExpense = (expense_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ExpensesService.deleteExpense(expense_id)
      .then(() => {
        success("Gasto eliminado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <ExpensesContext.Provider
      value={{
        ...state,
        setExpense,
        getExpenses,
        saveExpense,
        deleteExpense,
        createExpense,
        downloadExpenses,
        getSingleExpense,
        setPropertyExpense,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
};
