import React, { useContext } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { formatMonto } from "../../utils";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";

const AnaliticasMensuales = () => {
  const { invoices, expenses, getMensuales } = useContext(AnaliticasContext);

  const renderChart = () => {
    if (Array.isArray(invoices) && Array.isArray(expenses)) {
      const data = new Array(12).fill(1);
      const expenseData = new Array(12).fill(1);
      data.forEach((one, index) => {
        const month = invoices.find((mes) => mes.month === index + 1);
        const expenseMonth = expenses.find((mes) => mes.month === index + 1);
        data[index] = {
          total: month?.total || 0,
          mes: moment(index + 1, "MM").format("MMM"),
        };
        expenseData[index] = {
          total: expenseMonth?.total || 0,
          mes: moment(index + 1, "MM").format("MMM"),
        }
      });
      return (
        <Chart
          type="area"
          options={{
            dataLabels: {
              formatter: (val, opts) => {
                return `$${formatMonto(val)}`;
              },
            },
            xaxis: {
              categories: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
              ],
            },
          }}
          series={[
            { name: "Ingresos por Mes", data: data.map(({ total }) => total) },
            { name: "Gastos por Mes", data: expenseData.map(({ total }) => total) },
          ]}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Mensuales" callback={getMensuales} />
      <div className="card shadow">{renderChart()}</div>
    </div>
  );
};

export default AnaliticasMensuales;
