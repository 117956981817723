import React, { useState } from "react";
import { Link } from "@reach/router";
import { getValue } from "../../utils";
import ReactSwitch from "react-switch";

const ProductoForm = ({ spinner, modifier, postProducto, product }) => {
  const [isSubscription, setIsSubscription] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isSubscription) {
      if(product.subscription_period === "") {
        product.subscription_period = "days";
      }
    } else {
      product.subscription_period = null;
      product.subscription_interval = null;
    }
    postProducto(product);
  };

  const renderSubscription = () => {
    if (isSubscription) {
      return (
        <div className="row align-items-center mb-3">
          <label className="mb-1">Programar pago cada</label>
          <div className="col-12 col-md-2 mb-3">
            <input
              type="number"
              className="form-control"
              value={getValue(product, "subscription_interval")}
              onChange={(e) =>
                modifier("subscription_interval", e.target.value)
              }
            />
          </div>
          <div className="col-12 col-md-10 mb-3">
            <select
              className="form-control"
              value={getValue(product, "subscription_period")}
              onChange={(e) => modifier("subscription_period", e.target.value)}
            >
              <option value="">Seleccionar...</option>
              <option value="day">Día(s)</option>
              <option value="month">Mes(es)</option>
              <option value="year">Año(s)</option>
            </select>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(product, "name")}
          onChange={(e) => modifier("name", e.target.value)}
        />
        <label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(product, "short_description")}
          onChange={(e) => modifier("short_description", e.target.value)}
        />
        <label>Precio</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(product, "price")}
          onChange={(e) => modifier("price", e.target.value)}
        />
        <div className="row mb-3">
          <div className="col-10">
            <label>¿Programar pagos?</label>
          </div>
          <div className="col-2 text-end">
            <ReactSwitch
              checked={isSubscription}
              onChange={setIsSubscription}
            />
          </div>
        </div>
        {renderSubscription()}
        <div className="row">
          <div className="col-6">
            <Link
              to="/myadmin/products"
              className="btn btn-link text-secondary"
            >
              Cancelar
            </Link>
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductoForm;
