import React from "react";
import ExpenseRow from "./ExpenseRow";

const ExpensesTable = ({
  user,
  expenses,
  hideColumns,
  confirmCancel,
  confirmRevoke,
  handleCallback,
  editExpiration,
}) => {
  const renderExpenses = () => {
    if (Array.isArray(expenses)) {
      if (expenses.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      expenses.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateB - dateA;
      });

      return expenses.map((expense) => (
        <ExpenseRow
          user={user}
          expense={expense}
          key={expense.expense_id}
          hideColumns={hideColumns}
          confirmCancel={confirmCancel}
          confirmRevoke={confirmRevoke}
          handleCallback={handleCallback}
          editExpiration={editExpiration}
        />
      ));
    }
  };
  return (
    <div className="table-responsive" style={{ minHeight: "200px" }}>
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Concepto</td>
            <td>Fecha Límite</td>
            <td>Fecha Gasto</td>
            <td>Precio</td>
            <td>Método Pago</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderExpenses()}</tbody>
      </table>
    </div>
  );
};

export default ExpensesTable;
