import React, { createContext, useContext, useReducer } from "react";
import PagesReducer from "../reducers/PagesReducer";
import PagesService from "../services/PagesService";
import { PAGES_RECEIVED, SET_PAGE, SET_PROPERTY_CONTENT } from "../types/pages";
import { ModalContext } from "./ModalContext";

const initialState = {
  pages: null,
  page: null,
  content: null,
};

export const PagesContext = createContext(initialState);

export const PagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PagesReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getPages = () => {
    PagesService.getPages().then((res) => {
      const { pages } = res.data;
      dispatch({ type: PAGES_RECEIVED, payload: pages });
    });
  };

  const getSinglePage = (page_id) => {
    PagesService.getSinglePage(page_id).then((res) => {
      const { page } = res.data;
      dispatch({ type: SET_PAGE, payload: page });
    });
  };

  const getPageBySlug = (slug) => {
    return PagesService.getPageBySlug(slug)
      .then(res => {
        const { page } = res.data;
        dispatch({ type: SET_PAGE, payload: page });
        return res;
      })
      .catch(err => {
        return err;
      })
  }

  const createPage = () => {
    dispatch({ type: SET_PAGE, payload: { title: "Nueva Página" } });
  };

  const setPropertyContent = (content_id, key, value) => {
    dispatch({
      type: SET_PROPERTY_CONTENT,
      payload: { content_id, key, value },
    });
  };

  const postPage = (page) => {
    let service = PagesService.putPage;
    if (isNaN(page.page_id)) service = PagesService.postPage;
    return service(page)
      .then((res) => {
        return res;
      })
      .catch(err => {
        return err;
      });
  };

  const deletePage = (page_id) => {
    PagesService.deletePage(page_id)
      .then(() => {
        success("Pagina eliminada.");
      })
      .catch(alert);
  };

  return (
    <PagesContext.Provider
      value={{
        ...state,
        getPages,
        getSinglePage,
        setPropertyContent,
        getPageBySlug,
        createPage,
        postPage,
        deletePage,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};
