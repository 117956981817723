import React from "react";
import { formatMonto, getTotalCompras } from "../../utils";

const CustomerActions = ({ customer, showTotalPurchases }) => {

  const renderComprasTotales = () => {
    if (showTotalPurchases) {
      return (
        <h4 className="mt-4">
          Compras Totales: {"$"}
          {formatMonto(getTotalCompras(customer))} MXN
        </h4>
      );
    }
  };

  const renderCustomerTags = () => {
    if (Array.isArray(customer?.tags)) {
      return customer.tags.map((tag) => (
        <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
          {tag.name}
        </span>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      {renderComprasTotales()}
      <div className="col-6 bold">Etiquetas</div>
      {renderCustomerTags()}
    </div>
  );
};

export default CustomerActions;
