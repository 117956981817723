import React, { useContext } from "react";
import { Link } from "@reach/router";
import UserTabs from "../navbar/UserTabs";
import ManagerTabs from "../navbar/ManagerTabs";
import AnalyticsTabs from "../navbar/AnalyticsTabs";
import { AppConfigContext } from "../../context/AppConfigContext";

const PanelNavbar = ({ user, signOut }) => {
  const { S3_ENDPOINT, navbar_logo } = useContext(AppConfigContext);

  const renderAnalytics = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return <AnalyticsTabs />;
    }
  };

  const renderManagerTabs = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return <ManagerTabs />;
    }
  };

  return (
    <nav className="hide-mobile side-menu">
      <div className="container-fluid px-0">
        <div className="px-3">
          <Link to="/" className="navbar-brand">
            <img
              src={`${S3_ENDPOINT}/${navbar_logo}`}
              alt="Logo Navegación"
              className="thumbnail"
            />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="text-white">
          {renderManagerTabs()}
          {renderAnalytics()}
          <UserTabs user={user} signOut={signOut} />
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
