import {
  ADD_CLASS_PACKAGE_TYPE,
  CREATE_PRODUCTO,
  PRODUCTS_RECIBIDOS,
  REMOVE_CLASS_PACKAGE_TYPE,
  RESERVATIONS_RECIBIDAS,
  SET_PRODUCT_PROPERTY,
  SET_PRODUCT,
  SET_PROPIEDAD_PRODUCTO,
} from "../types";
import { generateRandomKey } from "../utils";

const schema = {
  product_id: "nuevo",
  name: "",
  short_description: "",
  description: "",
  price: "",
  subscription_period: "",
  subscription_interval: "",
};


const class_package_type_schema = {
  class_type_id: "",
};

const ProductosReducer = (state, { type, payload }) => {
  switch (type) {
    case PRODUCTS_RECIBIDOS:
      return { ...state, products: payload };
    case SET_PRODUCT:
      return { ...state, product: payload };
    case SET_PROPIEDAD_PRODUCTO: {
      const { key, value } = payload;
      const product = { ...state.product };
      product[key] = value;
      return { ...state, product };
    }
    case SET_PRODUCT_PROPERTY: {
      const { key, value, object_key } = payload;
      const product = { ...state.product };
      const class_package_types = [...product.class_package_types];
      
      const category = class_package_types.find(
        (category) => String(object_key) === String(category.object_key)
      );
      
      if (category) {
        category[key] = value;
      }
      return { ...state, product: { ...product, class_package_types } };
    }
    case ADD_CLASS_PACKAGE_TYPE: {
      const product = { ...state.product };
      let class_package_types = [...product.class_package_types];
      if (!Array.isArray(class_package_types)) {
        class_package_types = [];
      }
      // if (hasEmptyCategory(class_package_types)) {
      //   return { ...state, class_package_types };
      // }
  
      const current_schema = { 
        ...class_package_type_schema,
        object_key: generateRandomKey(),  
      };

      current_schema.product_id = product.product_id;
      class_package_types.push(current_schema);
      
      return {
        ...state,
        product: { ...product, class_package_types },
      };
    }
    case REMOVE_CLASS_PACKAGE_TYPE: {
      const product = { ...state.product };
      const class_package_types = [...product.class_package_types];
      if (Array.isArray(class_package_types)) {
        const index = class_package_types.findIndex(
          (current) =>
            String(current.object_key) ===
            String(payload)
        );
        if (index !== -1) {
          class_package_types.splice(index, 1);
        }
      }
      return { ...state, product: { ...product, class_package_types } };
    }
    case CREATE_PRODUCTO:
      return { ...state, product: schema };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, asistentes: payload };
    default:
      return { ...state };
  }
};

export default ProductosReducer;
