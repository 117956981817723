import React, { useState, useEffect } from "react";

const FileInput = ({ label, value, type, modifierKey, modifier }) => {
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (value && value !== null && type === "image") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPicture(e.target.result);
      };
      if (typeof value === "object") {
        if (value.size) {
          reader.readAsDataURL(value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFiles = (e) => {
    const currentFile = e.target.files[0];
    const fileSrc = URL.createObjectURL(currentFile);
    setPicture(fileSrc);
    
    if (typeof modifier === "function") {
      modifier(modifierKey, currentFile);
    }
  };

  const renderImage = () => {
    if (typeof value === "object" && value !== null && picture === null) {
      return (
        <img
          alt={value.name}
          src={value.src}
          className="mw-100 w-100 m-auto d-block"
        />
      );
    }
    if (picture !== null) {
      return (
        <img
          src={picture}
          alt="Imagen ingresada"
          className="d-block m-auto mw-100 w-100"
        />
      );
    }
    return (
      <div className="border text-center">
        <i className="fas fa-image fa-3x"></i>
      </div>
    );
  };

  return (
    <div className="row align-items-center my-3">
      <div className="col-4 col-md-4">{renderImage()}</div>
      <div className="col-8 col-md-8">
        <label>{label}</label>
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      </div>
    </div>
  );
};

export default FileInput;
