import React from "react";
import { Link } from "@reach/router";

const AnalyticsTabs = () => {
  return (
    <ul className="side-menu-list">
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#analiticasDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="analiticasDropdown"
        >
          Analíticas
        </a>
        <ul className="collapse collapse-menu" id="analiticasDropdown">
          <li className="nav-item text-item">
            <Link to="/myadmin/analytics/ingresos" className="nav-link">
              Ingresos
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/analytics/expenses" className="nav-link">
              Gastos
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/analytics/products" className="nav-link">
              Productos
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/analytics/mensuales" className="nav-link">
              Mensuales
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default AnalyticsTabs;
