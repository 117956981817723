import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import ProductoForm from "../../components/products/ProductForm";
import { ProductsContext } from "../../context/ProductsContext";

const AdminProductoForm = ({ product_id }) => {
  const {
    spinner,
    product,
    postProducto,
    createProducto,
    getProductoAdmin,
    clearSingleProducto,
    setPropiedadProducto,
  } = useContext(ProductsContext);

  useEffect(() => {
    return clearSingleProducto;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNaN(product_id)) {
      createProducto();
    } else {
      getProductoAdmin(product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  return (
    <div className="container-fluid">
      <Link
        to="/myadmin/products"
        className="btn btn-outline-dark bold shadow-sm mb-3"
      >
        {"< "}Regresar a Productos
      </Link>
      <h1 className="mb-0">
        {isNaN(product_id) ? "Agregar" : "Editar"} Producto
      </h1>
      <div className="card p-3  mt-3 shadow">
        {product && product !== null ? (
          <ProductoForm
            modifier={setPropiedadProducto}
            postProducto={postProducto}
            product={product}
            spinner={spinner}
          />
        ) : (
          <div className="spinner-border"></div>
        )}
      </div>
    </div>
  );
};

export default AdminProductoForm;
