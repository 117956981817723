import React, { useContext } from "react";
import { getValue } from "../../utils";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";

const PaymentMethodForm = ({ modifier, handleSave, handleCancel }) => {
  const { payment_method } = useContext(PaymentMethodsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(payment_method);
  };

  const renderForm = () => {
    if (payment_method && payment_method !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(payment_method, "name")}
            onChange={(e) => modifier("name", e.target.value)}
          />
          
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                onClick={handleCancel}
                className="btn w-100 text-muted"
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button type="submit" className="btn w-100 btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default PaymentMethodForm;
