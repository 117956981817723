import React, { useContext, useEffect } from "react";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { ExpensesContext } from "../../context/ExpensesContext";
import { getValue } from "../../utils";
import moment from "moment";

const ExpenseForm = ({ expense_id, handleCancel, handleCallback }) => {
  const {
    expense,
    saveExpense,
    createExpense,
    getSingleExpense,
    setPropertyExpense,
  } = useContext(ExpensesContext);

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  useEffect(() => {
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNaN(parseInt(expense_id))) {
      createExpense();
    } else {
      getSingleExpense(expense_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expense_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(expense.payment_method_id)) {
      return alert("Debes elegir un método de pago");
    }
    if (!moment(expense.due_date).isValid()) {
      return alert("Debes elegir una fecha límite");
    }
    if (!moment(expense.date).isValid()) {
      return alert("Debes elegir una fecha de pago");
    }
    saveExpense(expense, handleCallback);
  };

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return payment_methods.map((payment_method) => (
        <option
          key={payment_method.payment_method_id}
          value={payment_method.payment_method_id}
        >
          {payment_method.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Concepto</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(expense, "description")}
          onChange={(e) => setPropertyExpense("description", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <label>Fecha Límite</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(expense, "due_date")}
              onChange={(e) => setPropertyExpense("due_date", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Fecha Aplicado</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(expense, "date")}
              onChange={(e) => setPropertyExpense("date", e.target.value)}
            />
          </div>
        </div>

        <label>Total Pagado</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(expense, "amount")}
          onChange={(e) => setPropertyExpense("amount", e.target.value)}
        />

        <label className="d-block">Método de Pago</label>
        <select
          className="form-control mb-3"
          value={getValue(expense, "payment_method_id")}
          onChange={(e) =>
            setPropertyExpense("payment_method_id", e.target.value)
          }
        >
          <option value="">Seleccionar...</option>
          {renderPaymentMethods()}
        </select>
        <label>Estado</label>
        <select
          className="form-control mb-3"
          value={getValue(expense, "status")}
          onChange={(e) => setPropertyExpense("status", e.target.value)}
        >
          <option value="">Seleccionar...</option>
          <option value="completed">Pagado</option>
          <option value="pending">Pendiente</option>
          <option value="cancelled">Cancelado</option>
        </select>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ExpenseForm;
