import api from "./api";
import { getArgs } from "../utils";

const route = "/products";

const ProductosService = {
  getProductos: () => api.get(route),
  getProducto: (product_id) => api.get(`${route}/${product_id}`),
  getOnline: () => api.get(`${route}/online/all`),
  getProductoAdmin: (product_id) =>
    api.get(`${route}/admin/${product_id}`),
  getAllProductos: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`),
  getOnlineHome: () => api.get(`${route}/online/home`),
  getPresenciales: () => api.get(`${route}/presenciales/all`),
  getPresencialesHome: () => api.get(`${route}/presenciales/home`),
  getAsistentesEspecial: (product_id) =>
    api.get(`${route}/especiales/${product_id}/asistentes`),
  getEventosEspecialesHome: () => api.get(`${route}/especiales/home`),
  getEventosAllEspeciales: () => api.get(`${route}/especiales/all`),
  getEventosEspecialesAdmin: () => api.get(`${route}/admin/especiales`),
  getProductosEspeciales: () => api.get(`${route}/especiales/all`),
  postProducto: (product) => api.post(route, { ...product }),
  putProducto: (product) => api.put(route, product),
  deleteProducto: (product_id) =>
    api.delete(`${route}/${product_id}`),
};

export default ProductosService;
