import { CLEAR_INVOICES, HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_INVOICE,
  INVOICES_RECEIVED,
  SET_PROPERTY_INVOICE,
  CREATE_INVOICE,
} from "../types/invoices";

const InvoicesReducer = (state, { type, payload }) => {
  switch (type) {
    case INVOICES_RECEIVED:
      return { ...state, invoices: payload };
    case SET_INVOICE:
      return { ...state, invoice: payload };
    case SET_PROPERTY_INVOICE: {
      const invoice = { ...state.invoice };
      const { key, value } = payload;
      invoice[key] = value;
      return { ...state, invoice };
    }
    case CREATE_INVOICE: {
      return { ...state, invoice: {} };
    }
    case CLEAR_INVOICES:
      return { ...state, invoices: null };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default InvoicesReducer;
