import { getArgs } from "../utils";
import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: (filters) => api.get(`${route}/admin/all?${getArgs(filters)}`),
  downloadPurchases: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`, {
      responseType: "blob",
    }),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  putPurchase: (purchase) => api.put(route, { ...purchase }),
  postPurchase: (purchase) => api.post(route, { ...purchase }),
  cancelPurchase: (purchase_id, reason) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      reason,
      status: "cancelled",
    }),
  revokePurchase: (purchase_id) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      status: "revoked",
    }),
};

export default PurchasesService;
