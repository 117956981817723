import React, { createContext, useReducer } from "react";
import AnaliticasReducer from "../reducers/AnaliticasReducer";
import AnaliticasService from "../services/AnaliticasService";
import {
  INGRESOS_RECBIDOS,
  PRODUCTS_RECIBIDOS,
  INSCRITOS_RECIBIDOS,
  MENSUALES_RECIBIDOS,
} from "../types";

const initialState = {
  alumnas: null,
  products: null,
  ingresos: null,
};

export const AnaliticasContext = createContext(initialState);

export const AnaliticasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnaliticasReducer, initialState);

  const getInscritos = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getInscritos(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: INSCRITOS_RECIBIDOS, payload: res.data });
    });
  };

  const getProductos = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getProductos(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: PRODUCTS_RECIBIDOS, payload: res.data.purchases });
    });
  };

  const getIngresos = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getIngresos(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: INGRESOS_RECBIDOS, payload: res.data });
    });
  };

  const getExpenses = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getExpenses(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: INGRESOS_RECBIDOS, payload: res.data });
    });
  };

  const getMensuales = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getMensuales(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: MENSUALES_RECIBIDOS, payload: res.data });
    });
  };

  return (
    <AnaliticasContext.Provider
      value={{
        ...state,
        getExpenses,
        getIngresos,
        getMensuales,
        getProductos,
        getInscritos,
      }}
    >
      {children}
    </AnaliticasContext.Provider>
  );
};
