import React from "react";
import moment from "moment";

const CustomerData = ({ customer, handleEdit, hideEdit }) => {
  const { file, name, last_name, birthdate, phone, email } =
    customer;

  const renderBirthdate = () => {
    if (moment(birthdate).isValid()) {
      return moment(birthdate).utc().format("DD MMM YYYY");
    }
    return "N/D";
  };

  const renderEditBtn = () => {
    if(!hideEdit) {
      return(
        <button onClick={handleEdit} className="btn btn-outline-primary mt-3">
          <i className="fa fa-edit me-3"></i>Editar Información
        </button>
      )
    }
  }

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3 align-items-center mx-0">
        <div className="col-4 col-md-2 ps-0">
          <img
            src={file?.src}
            className="w-100 border p-3 br-10 profile-image"
            alt="customer profile"
          />
        </div>
        <div className="col-8 col-md-10">
          <h4 className="mb-0">
            {name} {last_name}
          </h4>
        </div>
      </div>
      <div className="mb-1">
        <i className="fa fa-envelope me-3"></i>
        {email}
      </div>
      <div className="mb-1">
        <i className="fa fa-birthday-cake me-3"></i>
        {renderBirthdate()}
      </div>
      <div className="mb-1">
        <i className="fa fa-phone me-3"></i>
        {phone}
      </div>
      {renderEditBtn()}
    </div>
  );
};

export default CustomerData;
