import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import ProductosReducer from "../reducers/ProductosReducer";
import ProductosService from "../services/ProductosService";
import {
  CREATE_PRODUCTO,
  HIDE_SPINNER,
  PRODUCTS_RECIBIDOS,
  RESERVATIONS_RECIBIDAS,
  SET_PRODUCT,
  SET_PROPIEDAD_PRODUCTO,
  ADD_CLASS_PACKAGE_TYPE,
  REMOVE_CLASS_PACKAGE_TYPE,
  SHOW_SPINNER,
  SET_PRODUCT_PROPERTY,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AdjuntosContext } from "./AdjuntosContext";

const initialState = {
  products: null,
  product: null,
  spinner: false,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductosReducer, initialState);
  const { success, alert } = useContext(ModalContext);
  const { postAdjunto } = useContext(AdjuntosContext);

  const getProductos = () => {
    ProductosService.getProductos().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECIBIDOS, payload: products });
    });
  };

  const clearSingleProducto = () => {
    dispatch({ type: SET_PRODUCT, payload: null });
  }

  const getProductosEspecialesAdmin = () => {
    ProductosService.getEventosEspecialesAdmin().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECIBIDOS, payload: products });
    });
  };

  const getAsistentesEspecial = (product_id) => {
    ProductosService.getAsistentesEspecial(product_id).then((res) => {
      const { product, asistentes } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: asistentes });
      dispatch({ type: SET_PRODUCT, payload: product });
    });
  };

  const getProductosOnline = () => {
    ProductosService.getOnline().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECIBIDOS, payload: products });
    });
  };

  const getAllProductos = (filters) => {
    ProductosService.getAllProductos(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECIBIDOS, payload: products });
    });
  };

  const getProductoAdmin = (product_id) => {
    ProductosService.getProductoAdmin(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_PRODUCT, payload: product });
    });
  };

  const createProducto = () => {
    dispatch({ type: CREATE_PRODUCTO });
  };

  const setPropiedadProducto = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_PRODUCTO, payload: { key, value } });
  };

  const setPropertyProductType = (object_key, key, value) => {
    dispatch({ type: SET_PRODUCT_PROPERTY, payload: { key, value, object_key} });
  }

  const addProductType = () => {
    dispatch({
      type: ADD_CLASS_PACKAGE_TYPE,
    });
  };

  const removeProductType = (object_key) => {
    dispatch({ type: REMOVE_CLASS_PACKAGE_TYPE, payload: object_key });
  }

  const postProducto = async (updatedPackage) => {
    dispatch({ type: SHOW_SPINNER });

    const callback = () => {
      success("¡Producto guardado con éxito!");
      navigate("/myadmin/products");
      dispatch({ type: HIDE_SPINNER });
    };

    const handleError = (error) => {
      alert(error);
      dispatch({ type: HIDE_SPINNER });
    };

    const image = updatedPackage.image;
    const receipt_file = updatedPackage.receipt_file;

    if(typeof image?.file_id !== 'number' && image) {
      const res = await postAdjunto(image)
      updatedPackage.thumbnail = res.data.file_id;
      delete updatedPackage.image;
    }

    if(typeof receipt_file?.file_id !== 'number' && receipt_file) {
      const res = await postAdjunto(receipt_file)
      updatedPackage.receipt = res.data.file_id;
      delete updatedPackage.receipt_file;
    }

    if (isNaN(updatedPackage.product_id)) {
      ProductosService.postProducto(updatedPackage).then(callback).catch(handleError);
    } else {
      ProductosService.putProducto(updatedPackage).then(callback).catch(handleError);
    }
  };
  

  const deleteProducto = (product_id) => {
    ProductosService.deleteProducto(product_id).then(() => {
      success("¡Producto eliminado con éxito!");
      getAllProductos();
      hideModal();
    });
  };

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        getProductos,
        postProducto,
        addProductType,
        deleteProducto,
        createProducto,
        getAllProductos,
        getProductoAdmin,
        removeProductType,
        getProductosOnline,
        clearSingleProducto,
        setPropiedadProducto,
        getAsistentesEspecial,
        setPropertyProductType,
        getProductosEspecialesAdmin,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
