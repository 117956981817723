import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";

const ProductoRow = ({ product, confirmDelete }) => {
  const { product_id, name, available, price, sale_price } = product;
  return (
    <div className="card p-2 hover-light  br-0">
      <div className="row small align-items-center">
        <div className="col">{name}</div>
        <div className="col">
          {available ? (
            <i className="fa fa-check text-success"></i>
          ) : (
            <i className="fa fa-times text-danger"></i>
          )}
        </div>
        <div className="col">
          {"$"}
          {formatMonto(price)}
        </div>
        <div className="col text-center">
          <Link
            className="btn btn-outline-primary btn-sm"
            to={`/myadmin/products/${product_id}`}
          >
            <i className="fa fa-edit"></i>
          </Link>
          <button
            className="btn btn-outline-danger btn-sm mx-3"
            onClick={() => confirmDelete(product)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductoRow;
