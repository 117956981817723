import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { TagsContext } from "../../context/TagsContext";
import HeaderRow from "../../components/global/HeaderRow";
import TagForm from "../../components/tags/TagForm";
import { ModalContext } from "../../context/ModalContext";

const AdminTags = () => {
  const { modalComponent, clearModal } = useContext(ModalContext);
  const { tags, setTag, getTags, createTag, deleteTag } =
    useContext(TagsContext);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    setTag(null);
  };

  const handleCreate = () => {
    createTag();
    modalComponent("Crear Etiqueta", <TagForm handleCancel={handleCancel} />);
  };

  const handleEdit = (tag) => {
    setTag(tag);
    modalComponent("Editar Etiqueta", <TagForm handleCancel={handleCancel} />);
  };

  const handleDelete = (tag) => {
    modalComponent(
      "Eliminar Etiqueta",
      <div>
        <p>¿Eliminar etiqueta {tag.name}?</p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button onClick={handleCancel} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deleteTag(tag.tag_id, getTags)}
              className="btn btn-danger w-100"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTags = () => {
    if (Array.isArray(tags)) {
      return tags.map((tag) => (
        <div className="row mx-0 py-2 border-bottom">
          <div className="col">{tag.name}</div>
          <div className="col">
            <div
              className="color-legend"
              style={{ backgroundColor: tag.color }}
            ></div>
          </div>
          <div className="col text-center">
            <button
              onClick={() => handleEdit(tag)}
              className="btn btn-sm btn-outline-dark"
            >
              <i className="fa fa-edit" />
            </button>
            <button
              onClick={() => handleDelete(tag)}
              className="btn btn-sm btn-outline-danger ms-2"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </div>
      ));
    }
  };
  return (
    <div className="container-fluid">
      <PanelTitle title="Etiquetas" onClick={handleCreate} />
      <div className="card shadow-sm">
        <HeaderRow headers={["Nombre", "Color", "Acciones"]} />
        {renderTags()}
      </div>
    </div>
  );
};

export default AdminTags;
