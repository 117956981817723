import React, { useContext, useEffect } from "react";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ProductsContext } from "../../context/ProductsContext";
import { getValue } from "../../utils";
import moment from "moment";

const PurchaseForm = ({ purchase_id, customer_id, handleCancel }) => {
  const {
    purchase,
    savePurchase,
    createPurchase,
    getSinglePurchase,
    setPropertyPurchase,
  } = useContext(PurchasesContext);

  const { products, getProductos } = useContext(ProductsContext);
  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  useEffect(() => {
    if (isNaN(parseInt(purchase_id))) {
      createPurchase();
    } else {
      getSinglePurchase(purchase_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_id]);

  useEffect(() => {
    if(getValue(purchase, "product_id") !== "") {
      if(getValue(purchase, "amount") === "") {
        const product = products.find(({ product_id }) => parseInt(product_id) === parseInt(getValue(purchase, "product_id")));
        if(product) setPropertyPurchase("amount", product.price);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase]);

  useEffect(() => {
    getPaymentMethods();
    getProductos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!purchase.customer_id) purchase.customer_id = customer_id;
    if (isNaN(purchase.product_id)) {
      return alert("Debes elegir un producto");
    }
    if (isNaN(purchase.payment_method_id)) {
      return alert("Debes elegir un método de pago");
    }
    if (!moment(purchase.due_date).isValid()) {
      return alert("Debes elegir una fecha límite");
    }
    if (!moment(purchase.date).isValid()) {
      return alert("Debes elegir una fecha de pago");
    }
    savePurchase(purchase);
  };

  const renderProductos = () => {
    if (products && products !== null) {
      return products.map((product) => (
        <option key={product.product_id} value={product.product_id}>
          {product.name}
        </option>
      ));
    }
  };

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return payment_methods.map((payment_method) => (
        <option
          key={payment_method.payment_method_id}
          value={payment_method.payment_method_id}
        >
          {payment_method.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Producto</label>
        <select
          className="form-control mb-3"
          onChange={(e) => setPropertyPurchase("product_id", e.target.value)}
        >
          <option value="">Seleccionar...</option>
          {renderProductos()}
        </select>
        <label>Monto</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(purchase, "amount")}
          onChange={(e) => setPropertyPurchase("amount", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <label>Fecha Límite</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(purchase, "due_date")}
              onChange={(e) => setPropertyPurchase("due_date", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Fecha Aplicado</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(purchase, "date")}
              onChange={(e) => setPropertyPurchase("date", e.target.value)}
            />
          </div>
        </div>
        <label className="d-block">Método de Pago</label>
        <select
          className="form-control mb-3"
          value={getValue(purchase, "payment_method_id")}
          onChange={(e) =>
            setPropertyPurchase("payment_method_id", e.target.value)
          }
        >
          <option value="">Seleccionar...</option>
          {renderPaymentMethods()}
        </select>
        <label>Estado</label>
        <select
          className="form-control mb-3"
          value={getValue(purchase, "status")}
          onChange={(e) => setPropertyPurchase("status", e.target.value)}
        >
          <option value="">Seleccionar...</option>
          <option value="completed">Completada</option>
          <option value="pending">Pendiente</option>
          <option value="cancelled">Cancelada</option>
        </select>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PurchaseForm;
