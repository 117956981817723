import api from "./api";

const route = "/analytics";

const AnaliticasService = {
  getInscritos: (start_date, end_date) =>
    api.get(`${route}/customers?start_date=${start_date}&end_date=${end_date}`),
  getProductos: (start_date, end_date) =>
    api.get(`${route}/purchases?start_date=${start_date}&end_date=${end_date}`),
  getIngresos: (start_date, end_date) =>
    api.get(`${route}/income?start_date=${start_date}&end_date=${end_date}`),
  getMensuales: (start_date, end_date) =>
    api.get(`${route}/monthly?start_date=${start_date}&end_date=${end_date}`),
  getExpenses: (start_date, end_date) =>
    api.get(`${route}/expenses?start_date=${start_date}&end_date=${end_date}`),
};

export default AnaliticasService;
