import React, { useContext, useEffect } from "react";
import moment from "moment";
import { InvoicesContext } from "../../context/InvoicesContext";
import { ModalContext } from "../../context/ModalContext";
import { getValue } from "../../utils";
import { PurchasesContext } from "../../context/PurchasesContext";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";

const InvoiceForm = ({
  invoice_id,
  customer_id,
  handleCancel,
  handleCallback,
}) => {
  const {
    invoice,
    getInvoice,
    postInvoice,
    createInvoice,
    setPropertyInvoice,
  } = useContext(InvoicesContext);
  const { clearModal } = useContext(ModalContext);
  const { purchases, getPurchases } = useContext(PurchasesContext);
  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  useEffect(() => {
    if (isNaN(parseInt(invoice_id))) {
      createInvoice();
    } else {
      getInvoice(invoice_id);
    }
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_id]);

  useEffect(() => {
    getPurchases({ customer_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    invoice.customer_id = customer_id;
    postInvoice(invoice, () => {
      handleCallback();
      clearModal();
    });
  };

  const renderPurchases = () => {
    if (Array.isArray(purchases) && purchases.length > 0)
      return purchases
        .filter((purchase) => !purchase.invoice_id)
        .map((purchase, index) => {
          const purchaseId = purchase.purchase_id;
          const purchasePackage = purchase.product?.name;
          const date = moment(purchase.createdAt)
            .local()
            .format("DD MMM YYYY HH:mm");

          const purchaseData = `#${purchaseId} - ${purchasePackage} ${date}`;

          return (
            <option key={purchaseId} value={purchaseId}>
              {purchaseData}
            </option>
          );
        });
  };

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods))
      return payment_methods.map((method) => {
        const methodId = method.payment_method_id;
        return (
          <option key={methodId} value={methodId}>
            {method.name}
          </option>
        );
      });
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Compra</label>
        <select
          className="form-control mb-3"
          value={getValue(invoice, "purchase_id")}
          onChange={(e) => setPropertyInvoice("purchase_id", e.target.value)}
        >
          <option value="">Seleccionar...</option>
          {renderPurchases()}
        </select>
        <label>Total</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(invoice, "amount")}
          onChange={(e) => setPropertyInvoice("amount", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <label>Fecha Límite</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(invoice, "due_date", "date")}
              onChange={(e) => setPropertyInvoice("due_date", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Fecha de Pago</label>
            <input
              type="date"
              className="form-control mb-3"
              value={getValue(invoice, "date", "date")}
              onChange={(e) => setPropertyInvoice("date", e.target.value)}
            />
          </div>
        </div>
        <label>Metodo de Pago</label>
        <select
          className="form-control mb-3"
          value={getValue(invoice, "payment_method_id")}
          onChange={(e) =>
            setPropertyInvoice("payment_method_id", e.target.value)
          }
        >
          <option value="">Seleccionar...</option>
          {renderPaymentMethods()}
        </select>
        <label>Estado</label>
        <select
          className="form-control mb-3"
          value={getValue(invoice, "status")}
          onChange={(e) => setPropertyInvoice("status", e.target.value)}
        >
          <option value="">Seleccionar...</option>
          <option value="pending">Pendiente</option>
          <option value="completed">Completado</option>
          <option value="cancelled">Cancelado</option>
        </select>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <input
              type="submit"
              className="btn btn-primary w-100"
              value="Registrar Cargo"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceForm;
