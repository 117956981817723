import { getArgs } from "../utils";
import api from "./api";

const route = "/expenses";

const ExpensesService = {
  getExpenses: (filters) => api.get(`${route}?${getArgs(filters)}`),
  downloadExpenses: (filters) =>
    api.get(`${route}?${getArgs(filters)}`, {
      responseType: "blob",
    }),
  getSingleExpense: (expense_id) => api.get(`${route}/${expense_id}`),
  postExpense: (expense) => api.post(route, { ...expense }),
  putExpense: (expense) => api.put(route, { ...expense }),
  deleteExpense: (Expense) => api.delete(`${route}/${Expense}`),
};

export default ExpensesService;
