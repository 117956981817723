import {
  CLEAR_PURCHASES,
  HIDE_SPINNER,
  PURCHASES_RECEIVED,
  SET_PURCHASE,
  SET_PROPERTY_PURCHASE,
  SHOW_SPINNER,
} from "../types";
import { CREATE_PURCHASE } from "../types/purchases";

const OrdenesReducer = (state, { type, payload }) => {
  switch (type) {
    case PURCHASES_RECEIVED:
      return { ...state, purchases: payload };
    case CLEAR_PURCHASES:
      return { ...state, purchases: null };
    case SET_PURCHASE:
      return { ...state, purchase: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_PROPERTY_PURCHASE: {
      const purchase = { ...state.purchase };
      const { key, value } = payload;
      purchase[key] = value;
      return { ...state, purchase };
    }
    case CREATE_PURCHASE:
      return {
        ...state,
        purchase: { product_id: null, payment_method_id: null },
      };
    default:
      return { ...state };
  }
};

export default OrdenesReducer;
