import React from "react";
import { Router } from "@reach/router";
import AdminTags from "./admin/AdminTags";
import AdminClientes from "./admin/AdminClientes";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminProductos from "./admin/AdminProductos";
import AdminProductoForm from "./admin/AdminProductoForm";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminPaymentMethods from "./admin/AdminPaymentMethods";
import AdminExpenses from "./admin/AdminExpenses";

const Admin = () => {

  return (
    <Router>
      <AdminTags path="/tags" />
      <AdminClientes path="/customers" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      <AdminProductos path="/products" />
      <AdminProductoForm path="/products/:product_id" />
      <AdminPurchases path="/orders" />
      <AdminExpenses path="/expenses" />
      <AdminUsuarios path="/users" />
      <AdminInvoices path="/subscriptions" />
      <AdminPaymentMethods path="/payment_methods"/>
    </Router>
  );
};

export default Admin;
