import React, { useContext, useEffect, useState } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import ProductoRow from "../../components/products/ProductRow";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";
import { navigate } from "@reach/router";
import HeaderRow from "../../components/global/HeaderRow";

const AdminProductos = () => {
  const [query, setQuery] = useState("");
  const { products, getAllProductos, deleteProducto } =
    useContext(ProductsContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAllProductos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProductos = () => {
    if (products && products !== null) {
      let paquetesRender = products;
      if (query !== "") {
        paquetesRender = paquetesRender.filter((product) =>
          product.name.toLowerCase().includes(query)
        );
      }
      if (paquetesRender.length === 0) {
        return <p className="p-2">No hay products disponibles.</p>;
      }
      return paquetesRender.map((product) => (
        <ProductoRow
          key={product.product_id}
          product={product}
          confirmDelete={confirmDelete}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const confirmDelete = (product) => {
    modalComponent(
      "Precaución",
      <>
        <p>
          ¿Estás segura que deseas eliminar el product {product.name}? Esta
          acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteProducto(product.product_id)}
        >
          Eliminar
        </button>
      </>
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Productos"
        onClick={() => navigate("/myadmin/products/nuevo")}
      />
      <div className="card shadow">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar product por nombre..."
        />
        <HeaderRow
          headers={["Título", "Disponible", "Precio", "Acciones"]}
        />
        {renderProductos()}
      </div>
    </div>
  );
};

export default AdminProductos;
