import React, { useContext, useEffect, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import SearchSelect from "../../components/common/SearchSelect";
import { PurchasesContext } from "../../context/PurchasesContext";
import Pagination from "../../components/global/Pagination";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import { UserContext } from "../../context/UserContext";
import { ProductsContext } from "../../context/ProductsContext";

const AdminPurchases = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [field, setField] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [packageId, setPackageId] = useState("");

  const { spinner, purchases, getPurchases, downloadPurchases } =
    useContext(PurchasesContext);

  const { products, getAllProductos } = useContext(ProductsContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getAllProductos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(user?.role !== 'coach') {
      if (startDate !== "" && endDate !== "" && !spinner) {
        fetchPurchases();
      }
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, packageId, page, status, query]);

  const fetchPurchases = () => {
    getPurchases(startDate, endDate, {
      page,
      status,
      query,
      field,
      product_id: packageId,
    });
  };

  const handleDownload = () => {
    downloadPurchases(startDate, endDate, {
      page,
      status,
      query,
      field,
      excel: true,
      product_id: packageId,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const renderProductos = () => {
    if (Array.isArray(products)) {
      return (
        <SearchSelect
          items={products}
          itemText="title"
          itemValue={`product_id`}
          placeholderText={`Producto...`}
          modifier={(selectData) => setPackageId(selectData.value)}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Pagos" callback={setDates} />
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-8">
            <input
              type="text"
              value={query}
              className="form-control mb-3"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar por nombre o correo electrónico..."
            />
          </div>
          <div className="col-4">
            <select
              className="form-control"
              value={field}
              onChange={(e) => setField(e.target.value)}
            >
              <option value="">Todo</option>
              <option value="name">Nombre</option>
              <option value="email">Correo</option>
              <option value="tags">Etiquetas</option>
            </select>
          </div>
        </div>
       
       
      </div>
      <div className="card  p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("active")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["active", "completed"])}
            >
              Activos
            </button>
            <button
              className={`btn btn-sm btn-${
                status === "cancelled" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("cancelled")}
            >
              Cancelados
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("pending")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["pending", "failed"])}
            >
              Fallidos
            </button>
          </div>
          <div className="col-6 col-md-4">{renderProductos()}</div>
          <div className="col-6 col-md-2">
            <button
              onClick={handleDownload}
              className="btn w-100 btn-outline-dark"
            >
              <i className="fa me-2 fa-file-excel"></i>{" "}
              <span className="hide-tablet">Descargar</span>
            </button>
          </div>
        </div>
        <PurchasesTable
          purchases={purchases}
          user={user}
          hideColumns={["type"]}
        />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminPurchases;
