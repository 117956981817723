import React, { useContext } from "react";
import { ExpensesContext } from "../../context/ExpensesContext";
import { ModalContext } from "../../context/ModalContext";
import StatusBadge from "../common/StatusBadge";
import { formatMonto, getValue } from "../../utils";
import ExpenseForm from "./ExpenseForm";
import moment from "moment";

const ExpenseRow = ({ expense, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const { setExpense } = useContext(ExpensesContext);

  const handleCancel = () => {
    clearModal();
    setExpense(null);
  };

  const handleEdit = () => {
    setExpense(expense);
    modalComponent(
      "Editar Compra",
      <ExpenseForm
        expense_id={expense.expense_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const renderPaymentMethod = () => {
    if (expense.payment_method && expense.payment_method !== null) {
      return expense.payment_method.name;
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>{expense.expense_id}</td>
      <td>{getValue(expense, "description")}</td>
      <td>{moment(expense.due_date).local().format("DD MMM YYYY HH:mm")}</td>
      <td>{moment(expense.date).local().format("DD MMM YYYY HH:mm")}</td>
      <td>{formatMonto(expense.amount)} MXN</td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={expense.status} date={expense.updatedAt} />
      </td>
      <td>
        <button onClick={handleEdit} className="btn btn-sm btn-outline-dark">
          <i className="fa fa-edit" />
        </button>
      </td>
    </tr>
  );
};

export default ExpenseRow;
