import React from "react";
import { Link } from "@reach/router";

const ManagerTabs = () => {
  return (
    <ul className="side-menu-list">
      <li className="nav-item text-item">
        <Link to="/myadmin/customers" className="nav-link">
          Clientes
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/orders" className="nav-link">
          Pagos
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/expenses" className="nav-link">
          Gastos
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/products" className="nav-link">
          Productos
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/payment_methods" className="nav-link">
          Métodos de Pago
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/tags" className="nav-link">
          Etiquetas
        </Link>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/users" className="nav-link">
          Usuarios
        </Link>
      </li>
    </ul>
  );
};

export default ManagerTabs;
