import { getArgs } from "../utils";
import api from "./api";

const route = "/invoices";

const InvoicesService = {
  getInvoicesAdmin: (startDate, endDate, args) =>
    api.get(
      `${route}/admin?start_date=${startDate}&end_date=${endDate}&${getArgs(
        args
      )}`
    ),
  getSingleInvoice: invoice_id => api.get(`${route}/${invoice_id}`),
  putInvoice: (data) => api.post(route, data),
  postInvoice: (data) => api.post(route, data),
};

export default InvoicesService;
