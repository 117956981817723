import React from "react";
import { Router } from "@reach/router";
import AnaliticasExpenses from "./analiticas/AnaliticasExpenses";
import AnaliticasIngresos from "./analiticas/AnaliticasIngresos";
import AnaliticasInscritos from "./analiticas/AnaliticasInscritos";
import AnaliticasProductos from "./analiticas/AnaliticasProductos";
import AnaliticasDescuentos from "./analiticas/AnaliticasDescuentos";
import AnaliticasMensuales from "./analiticas/AnaliticasMensuales";


const Analitica = () => {
  return (
    <div>
      <Router>
        <AnaliticasInscritos path="/inscritos" default />
        <AnaliticasDescuentos path="descuentos" />
        <AnaliticasMensuales path="/mensuales" />
        <AnaliticasProductos path="/products" />
        <AnaliticasIngresos path="/ingresos" />
        <AnaliticasExpenses path="/expenses" />
      </Router>
    </div>
  );
};

export default Analitica;
