import React, { createContext, useContext, useReducer } from "react";
import { SET_FORM, SET_FORM_PROPERTY, SET_PROPERTY, SET_TESTIMONIO, SET_TESTIMONIOS, UPDATE_FORM } from "../types/testimonios";
import { ModalContext } from "./ModalContext";
import TestimoniosReducer from "../reducers/TestimoniosReducer";
import TestimoniosService from "../services/TestimoniosService";
const grayImg = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjMyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4=';

const initialState = {
  testimonios: [],
  testimonio: {
    customer_name: '',
    customer_id: null,
    customer_file_id: '',
    description: '',
    category: '',
    rating: 0,
    after_file_id: null,
    before_file_id: null,
    status: '',
    show_home: true,
    order: null,
  },
  testimonio_form: {
    customer_name: '',
    customer_id: null,
    customer_image_src: grayImg,
    customer_image_file: null,
    description: '',
    category: 'online',
    rating: 5,
    before_file: null,
    before_src: grayImg,
    after_file: null,
    after_src: grayImg,
    show_home: true,
    order: null,
    customer_opts: []
  }
};

export const TestimoniosContext = createContext(initialState);

export const TestimoniosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TestimoniosReducer, initialState);
  const { alert } = useContext(ModalContext);

  const getAllTestimonios = () => {
    TestimoniosService.getAllTestimonios().then((res) => {
      const allTestimonios = res.data;
      dispatch({ type: SET_TESTIMONIOS, payload: allTestimonios });
    });
  };

  const getTestimonioById = (testament_id) => {
    TestimoniosService.getTestimonioById(testament_id).then((res) => {
      const testimonio = res.data;
      dispatch({ type: SET_TESTIMONIO, payload: testimonio });
    });
  };

  const postTestimonio = (data) => {
    return TestimoniosService.postTestament(data)
      .then(res => {
        return res.data;        
      })
      .catch(err => {
        alert(err);
      })
  }

  const updateTestimonio = (testimonio_id, data) => {
    return TestimoniosService.updateTestament(testimonio_id, data)
      .then(res => {
        return res;
      })
      .catch(err => {
        alert(err);
      })
  }

  const deleteTestimonio = (testament_id) => {
    return TestimoniosService.deleteTestament(testament_id)
      .then(res => {
        return res;
      })
      .catch(err => {
        alert(err);
      })
  }

  const setTestimonioProperty = (key, value) => {
    dispatch({ type: SET_PROPERTY, payload: { key, value } });
  }

  const setTestimonios = (payload) => {
    dispatch({ type: SET_TESTIMONIOS, payload });
  }

  const setTestimonioFormProperty = (key, value) => {
    dispatch({ type: SET_FORM_PROPERTY, payload: { key, value } });
  }

  const updateTestimoniosForm = (payload) => {
    dispatch({ type: UPDATE_FORM, payload });
  }

  const clearForm = () => {
    dispatch({ type: SET_FORM, payload: initialState.testimonio_form });
    
  }

  return (
    <TestimoniosContext.Provider value={{
      ...state, 
      getTestimonioById,
      updateTestimoniosForm,
      getAllTestimonios,
      postTestimonio,
      updateTestimonio,
      deleteTestimonio,
      setTestimonioProperty,
      setTestimonios,
      setTestimonioFormProperty,
      clearForm
    }}>
      {children}
    </TestimoniosContext.Provider>
  );
};
