import React, { useContext, useEffect, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import ExpensesTable from "../../components/expenses/ExpensesTable";
import { ExpensesContext } from "../../context/ExpensesContext";
import Pagination from "../../components/global/Pagination";
import { UserContext } from "../../context/UserContext";
import ExpenseForm from "../../components/expenses/ExpenseForm";
import { ModalContext } from "../../context/ModalContext";

const AdminExpenses = () => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { user } = useContext(UserContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, expenses, getExpenses, downloadExpenses } =
    useContext(ExpensesContext);

  useEffect(() => {
    if (user?.role !== "coach") {
      if (startDate !== "" && endDate !== "" && !spinner) {
        fetchExpenses();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, page, status]);

  const handleCreate = () => {
    modalComponent(
      "Agregar Gasto",
      <ExpenseForm handleCancel={clearModal} handleCallback={fetchExpenses} />
    );
  };

  const fetchExpenses = () => {
    getExpenses({
      start_date: startDate,
      end_date: endDate,
      page,
      status,
    });
  };

  const handleDownload = () => {
    downloadExpenses({
      start_date: startDate,
      end_date: endDate,
      excel: true,
      status,
      page,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Gastos" callback={setDates} />
      <div className="card p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-6 col-md-8">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("active")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["active", "completed"])}
            >
              Completados
            </button>
            <button
              className={`btn btn-sm btn-${
                status === "cancelled" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("cancelled")}
            >
              Cancelados
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("pending")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus("pending")}
            >
              Pendientes
            </button>
          </div>
          <div className="col-3 col-md-2">
            <button
              onClick={handleDownload}
              className="btn w-100 btn-outline-dark"
            >
              <i className="fa me-2 fa-file-excel"></i>{" "}
              <span className="hide-tablet">Descargar</span>
            </button>
          </div>
          <div className="col-3 col-md-2">
            <button onClick={handleCreate} className="btn w-100 btn-primary">
              <i className="fa fa-plus me-2" />
              Agregar
            </button>
          </div>
        </div>
        <ExpensesTable expenses={expenses} user={user} hideColumns={["type"]} handleCallback={fetchExpenses} />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminExpenses;
