import {
  SET_EXPENSE,
  CREATE_EXPENSE,
  EXPENSES_RECEIVED,
  SET_PROPERTY_EXPENSE,
} from "../types/expenses";

const schema = {};

const expensesReducer = (state, { type, payload }) => {
  switch (type) {
    case EXPENSES_RECEIVED:
      return { ...state, expenses: payload };
    case SET_EXPENSE:
      return { ...state, expense: payload };
    case CREATE_EXPENSE:
      return { ...state, expense: schema };
    case SET_PROPERTY_EXPENSE: {
      const { key, value } = payload;
      const expense = { ...state.expense };
      expense[key] = value;
      return { ...state, expense };
    }
    default:
      return { ...state };
  }
};

export default expensesReducer;
