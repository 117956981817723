import React, { createContext, useContext, useReducer } from "react";
import InvoicesReducer from "../reducers/InvoicesReducer";
import InvoicesService from "../services/InvoicesService";
import {
  CLEAR_INVOICES,
  HIDE_SPINNER,
  INVOICES_RECIBIDOS,
  SHOW_SPINNER,
} from "../types";
import { ModalContext } from "./ModalContext";
import {
  CREATE_INVOICE,
  SET_INVOICE,
  SET_PROPERTY_INVOICE,
} from "../types/invoices";

const initialState = {
  spinner: false,
  invoices: null,
  invoice: null,
};

export const InvoicesContext = createContext(initialState);

export const InvoicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoicesReducer, initialState);

  const { alert } = useContext(ModalContext);

  const getInvoices = (start_date, end_date, filters) => {
    dispatch({ type: CLEAR_INVOICES });
    InvoicesService.getInvoicesAdmin(start_date, end_date, filters).then(
      (res) => {
        const { invoices } = res.data;
        dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
      }
    );
  };

  const getSingleInvoice = (invoice_id) => {
    InvoicesService.getSingleInvoice(invoice_id).then((res) => {
      const { invoice } = res.data;
      dispatch({ type: SET_INVOICE, payload: invoice });
    });
  };

  const createInvoice = () => {
    dispatch({ type: CREATE_INVOICE });
  };

  const setPropertyInvoice = (key, value) => {
    dispatch({ type: SET_PROPERTY_INVOICE, payload: { key, value } });
  };

  const postInvoice = (invoice, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = InvoicesService.putInvoice;
    if (isNaN(parseInt(invoice.invoice_id))) {
      service = InvoicesService.postInvoice;
    }
    service(invoice)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        if (typeof callback === "function") callback();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <InvoicesContext.Provider
      value={{
        ...state,
        getInvoices,
        postInvoice,
        createInvoice,
        getSingleInvoice,
        setPropertyInvoice,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};
