import React, { useContext } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import { formatMonto } from "../../utils";
import Chart from "react-apexcharts";

const AnaliticasProductos = () => {
  const { products, getProductos } = useContext(AnaliticasContext);

  const renderChart = () => {
    if (Array.isArray(products)) {
      let globalTotal = 0.0;
      products.forEach((product) => {
        globalTotal += parseFloat(product.total);
      });
      return (
        <Chart
          type="donut"
          height="415"
          width="100%"
          options={{
            labels: products.map(({ title }) => title),
          }}
          series={products.map(({ total }) => total / globalTotal)}
        />
      );
    }
  };

  const renderAlumnas = () => {
    if (products && products !== null) {
      return products.map((product) => (
        <div className="row p-2" key={product.product_id}>
          <div className="col">{product.name}</div>
          <div className="col">{product.purchases}</div>
          <div className="col">
            {"$"}
            {formatMonto(product.total)}
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Productos" callback={getProductos} />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm p-3 me-3  my-3">
            <h4>Compras por Producto</h4>
            <HeaderRow headers={["Nombre", "Compras", "Total"]} />
            {renderAlumnas()}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow-sm p-3 me-3  my-3">
            <h4>Ingresos por Producto</h4>
            {renderChart()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasProductos;
